import { useAppSelector } from 'hooks/reduxHooks'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import { isMobileAppSel } from 'selectors/configSelectors'

export default function useTripClientSource(): string {
  const sMobileScreen = useScreenSizeOnly('mobile')
  const isApp = useAppSelector(isMobileAppSel)

  if (sMobileScreen) {
    if (isApp) {
      return 'mobile_app_trip_planner'
    } else {
      return 'mobile_trip_planner'
    }
  } else {
    return 'trip_planner'
  }
}
